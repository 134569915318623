import { useEffect, useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { useNavigate } from "react-router-dom";
import Modal from "../component/modal/Modal";

import { useFormik } from "formik";

import { registrationSchema } from "../schemas/register";

const Register = () => {
  const { signup, error, isLoading } = useSignup();
  const [done, setDone] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const navigate = useNavigate();

  const modalMessage =
    "Your account needs to be verified by the system admin before you can use it. Please wait for our email about the successful verification of your account within 24-48 hours";

  const handleSuccessClose = (e) => {
    e.preventDefault();

    navigate("/");
  };

  const onSubmit = async (values, actions) => {
    await signup(values);

    setDone(true);

    // actions.resetForm();
  };

  useEffect(() => {
    if (error) {
      setModalConfig({
        type: "danger",
        message: error,
        title: "Error",
        onClose: () => {
          setDone(false);
        },
      });
    } else {
      setModalConfig({
        type: "success",
        message: modalMessage,
        title: "Success",
        onClose: (e) => {
          e.preventDefault();
          navigate("/");
        },
      });
    }
  }, [error, navigate]);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      lastName: "",
      firstName: "",
      middleName: "",
      companyName: "",
    },
    validationSchema: registrationSchema,
    onSubmit,
  });

  return (
    <div className="signup__margin">
      <Modal
        isOpen={done}
        type={modalConfig.type}
        message={modalConfig.message}
        title={modalConfig.title}
        onClose={modalConfig.onClose}
      />
      <form onSubmit={handleSubmit}>
        <div className="center">
          <p>
            Email <span className="required">*</span>
          </p>
          <input
            type="email"
            id="email"
            value={values.email}
            onChange={handleChange}
            className={errors.email && touched.email ? "input-error" : ""}
          />
          {errors.email && touched.email && (
            <div className="error">{errors.email}</div>
          )}
          <p>
            Password <span className="required">*</span>
          </p>
          <input
            type="password"
            id="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.password && touched.password ? "input-error" : ""}
          />
          {errors.password && touched.password && (
            <div className="error">{errors.password}</div>
          )}
          <p>
            Confirm Password <span className="required">*</span>
          </p>
          <input
            type="password"
            id="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.confirmPassword && touched.confirmPassword
                ? "input-error"
                : ""
            }
          />
          {errors.confirmPassword && touched.confirmPassword && (
            <div className="error">{errors.confirmPassword}</div>
          )}
          <p>
            Last Name <span className="required">*</span>
          </p>
          <input
            type="text"
            id="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.lastName && touched.lastName ? "input-error" : ""}
          />
          {errors.lastName && touched.lastName && (
            <div className="error">{errors.lastName}</div>
          )}
          <p>
            First Name <span className="required">*</span>
          </p>
          <input
            type="text"
            id="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.firstName && touched.firstName ? "input-error" : ""
            }
          />
          {errors.firstName && touched.firstName && (
            <div className="error">{errors.firstName}</div>
          )}
          <p>
            Middle Name <span className="required">*</span>
          </p>
          <input
            type="text"
            id="middleName"
            value={values.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.middleName && touched.middleName ? "input-error" : ""
            }
          />
          {errors.middleName && touched.middleName && (
            <div className="error">{errors.middleName}</div>
          )}
          <p>
            Company Name<span className="required">*</span>
          </p>
          <input
            type="text"
            id="companyName"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.companyName && touched.companyName ? "input-error" : ""
            }
          />
          {errors.companyName && touched.companyName && (
            <div className="error">{errors.companyName}</div>
          )}
          <button disabled={isLoading} className="btn-sign__up" type="submit">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
