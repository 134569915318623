import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLogin } from "../hooks/useLogin";

function Home() {
  const [formData, setFormData] = useState("");
  const { login, isLoading, error } = useLogin();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(formData);
  };

  useEffect(() => {
    console.log(error);
    // navigate("/generate");
  }, [error, navigate]);

  return (
    <div className="index__center">
      <form className="center" onSubmit={handleSubmit}>
        <p>
          Email <span className="required">*</span>
        </p>
        <input
          type="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <p>
          Password <span className="required">*</span>
        </p>
        <input
          type="password"
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
        <button disabled={isLoading} className="btn-sign__up">
          Log In
        </button>
        {error && <div className="error">{error}</div>}
        <div className="d-flex">
          <Link className="sign-up" to="/signup">
            Sign Up
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Home;
