import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ResponseDocu from "./ResponseDocu";
import Decrypted from "./Decrypted";

const ApiCard = ({
  method,
  title,
  url,
  header,
  authentication,
  contentType,
  response,
  decryptedFields,
  query,
}) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };
  const queryString = `Query: [${query.join(", ")}]`;
  const queryStringDescriptor = query
    .map((field) =>
      field
        .replace(/\s+/g, "")
        .replace(/\s*\([^)]*\)/g, "")
        .trim()
        .replace(":", "=")
    )
    .join("&");
  useEffect(() => {
    const icon = document.getElementById("collapse-icon");
    if (icon) {
      icon.style.transition = "transform 0.3s ease-in-out";
      icon.style.transform = isCollapsed ? "rotate(0deg)" : "rotate(-180deg)";
    }
  }, [isCollapsed]);
  return (
    <div className="box">
      <p>{`[${method}] ${title}`}</p>
      <div className="inline-box-icon" onClick={toggleCollapse}>
        <small>{`${url}?${queryStringDescriptor}`}</small>
        <small className="collapse">
          {isCollapsed ? (
            <FontAwesomeIcon icon={faAngleDown} id="collapse-icon" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} id="collapse-icon" />
          )}
        </small>
      </div>
      {!isCollapsed && (
        <div>
          <h4>Header</h4>
          <p>Content-Type</p>
          <small>{contentType}</small>
          <p>Authentication</p>
          <small>{authentication}</small>
          <p>Parameters</p>
          <small>{queryString}</small>
          <ResponseDocu />
          <Decrypted decryptedFields={decryptedFields} />
        </div>
      )}
    </div>
  );
};

export default ApiCard;
