export const resCode = {
  112: "Email already registered",
  111: "Account needs to be verified. Please wait for the verification process.",
  300: "Invalid Subscription",
  301: "User does not exist",
  302: "User already exists",
  303: "Account creation failed",
  304: "Username or password is invalid",
  305: "Sign in failed",
  306: "User is already verified",
  500: "Could not connect to the server.Please try again later",
};
