const ResponseDocu = ({ decryptedFields }) => {
  return (
    <>
      <br />
      <p>Decrypted Data</p>
      <small>{"{"}</small>
      <br />
      {decryptedFields.map((string) => {
        return (
          <>
            <small>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              {string}
            </small>
            <br />
          </>
        );
      })}
      <small>{"}"}</small>
    </>
  );
};

export default ResponseDocu;
