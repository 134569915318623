import { useLogout } from "../hooks/useLogout";
import { useGenerate } from "../hooks/useGenerate";
import { useNavigate } from "react-router-dom";
import ApiCard from "../component/ApiCard";
import {
  applicants,
  certificates,
  client,
  jobs,
  talent,
  workHistory,
} from "../constants/decryptedResponse";

const Generate = () => {
  const { logout } = useLogout();
  const { generate, accessKeyId, secretAccessKey, isLoading, error } =
    useGenerate();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleGenerate = async (e) => {
    e.preventDefault();

    await generate();
  };

  const handleCopy = (state) => {
    navigator.clipboard.writeText(state);
  };

  const endpoint = process.env.REACT_APP_PROXY_URL;

  return (
    <div className="generate__center">
      <div className="center">
        <div className="divide-input__two">
          <div className="width-sp-100__width-50-pc">
            <label>Access Key Id</label>
            <input type="text" value={accessKeyId} readOnly />
            <input
              type="submit"
              value="Copy"
              onClick={() => handleCopy(accessKeyId)}
            />
          </div>

          <div className="width-sp-100__width-50-pc">
            <label>Secret Access Key</label>
            <input type="text" value={secretAccessKey} readOnly />
            <input
              type="submit"
              value="Copy"
              onClick={() => handleCopy(secretAccessKey)}
            />
          </div>
        </div>
        <button
          disabled={isLoading}
          onClick={handleGenerate}
          className="btn-generate__api"
        >
          Generate API Key
        </button>
        {error && <div className="error">{error}</div>}
        <p>
          <small>
            Please save the secret access key once generated. This key is never
            saved and there is no way to get this key again. If a key is lost,
            please generate a new one but take note that the previously
            generated key will no longer work
          </small>
        </p>
        <button className="logout" onClick={handleLogout}>
          Logout
        </button>
        <ApiCard
          method={"GET"}
          title={"Talents"}
          url={
            // "https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/talents"
            `${endpoint}/v1/OnePHP/talents`
          }
          query={["lastKey(optional): string"]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={talent}
        />
        <ApiCard
          method={"GET"}
          title={"Job Posts"}
          url={
            // "https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/jobPosts"
            `${endpoint}/v1/OnePHP/jobPosts`
          }
          query={[
            "lastKey(optional): string",
            "date(optional): string(YYYY-MM-DD or ISO)",
          ]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={jobs}
        />
        <ApiCard
          method={"GET"}
          title={"Clients"}
          url={
            //"https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/clients"
            `${endpoint}/v1/OnePHP/clients`
          }
          query={["lastKey(optional): string"]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={client}
        />
        <ApiCard
          method={"GET"}
          title={"Work History"}
          url={
            //"https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/workHistory"
            `${endpoint}/v1/OnePHP/workHistory`
          }
          query={[
            "lastKey(optional): string",
            "date(optional): string(YYYY-MM-DD or ISO)",
          ]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={workHistory}
        />
        <ApiCard
          method={"GET"}
          title={"Certificates"}
          url={
            // "https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/certificates"
            `${endpoint}/v1/OnePHP/certificates`
          }
          query={[
            "lastKey(optional): string",
            "date(optional): string(YYYY-MM-DD or ISO)",
          ]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={certificates}
        />
        <ApiCard
          method={"GET"}
          title={"Applicants"}
          url={
            //"https://0926alj1r1.execute-api.ap-southeast-1.amazonaws.com/latest/v1/OnePHP/jobApplications"
            `${endpoint}/v1/OnePHP/jobApplications`
          }
          query={["lastKey(optional): string"]}
          header={"Authentication"}
          authentication={`AccessKeyId and SecretAccessKey`}
          contentType={"application/json"}
          decryptedFields={applicants}
        />
      </div>
    </div>
  );
};

export default Generate;
