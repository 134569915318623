import React, { useState } from "react";

const Modal = ({ isOpen, onClose, type, message, title }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className={`modal-icon color__${type}`}>
              <span className="txt-white">?</span>
            </div>
            <button className="btn close-btn" onClick={onClose}>
              &times;
            </button>
            <h2>{title}</h2>
            <p className="modal-message">{message}</p>
            <button className={`btn-default color__${type}`} onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
