import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,21}$/gm;

//min 5 characters, maximum 21, 1 upper case letter, 1 lower case letter, 1 numeric digit

export const registrationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Field required"),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, {
      message:
        "5-21 characters with at least 1 lowercase and uppercase letters and 1 number",
    })
    .required("Field required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
  lastName: yup.string().required("Field required"),
  firstName: yup.string().required("Field required"),
  middleName: yup.string(),
  companyName: yup.string().required("Field required"),
});
