import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { resCode } from "../constants/resCode";

import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (signupData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_ENDPOINT}/signin`,
        data: signupData,
      });

      localStorage.setItem("user", JSON.stringify(response));

      dispatch({ type: "LOGIN", payload: response });
    } catch (err) {
      setIsLoading(false);
      if (err.response) setError("Email or Password is Incorrect");
      else setError(resCode[500]);
    }

    setIsLoading(false);
  };

  return { login, isLoading, error };
};
