import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { resCode } from "../constants/resCode";

import axios from "axios";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (signupData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_ENDPOINT}/signup`,
        data: signupData,
      });
    } catch (err) {
      setIsLoading(false);
      if (err.response) {
        setError(resCode[err.response.data.resCode]);
      } else setError(resCode[500]);
    }
    setIsLoading(false);
  };
  return { signup, isLoading, error };
};
