const ResponseDocu = () => {
  return (
    <>
      <p>Response</p>
      <small>{"{"}</small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {"code: 0 or 1 (0 for success and 1 for failed)"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {"message: string,"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {"result:{"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {"encryptedData: string"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {"base64IV: string,"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {"count: number,"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        {"lastKey: string"}
      </small>
      <br />
      <small>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {"}"}
      </small>
      <br />
      <small>{"}"}</small>
    </>
  );
};

export default ResponseDocu;
