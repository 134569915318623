export const client = [
  "clientId: string",
  "address: encrypted",
  "companyName: string",
  "companySize: string",
  "country: string",
  "createdAt: string",
  "industry: string",
  "isDeleted: number",
  "lastName: encrypted",
  "locked: boolean",
  "middleName: encrypted",
  "municipality: string",
  "otherState: string",
  "postal: string",
  "province: string",
  "region: string",
  "retentionDate: number",
];

export const talent = [
  "talentId: string",
  "address: encrypted",
  "birthday: string",
  "country: string",
  "createdAt: string",
  "email: encrypted",
  "isDeleted: number",
  "municipality: string",
  "postal: string",
  "province: string",
  "region: string",
  "retentionDate: number",
  "totalExperience: number",
];

export const jobs = [
  "id: string",
  "createdAt: string",
  "benefits: list of strings",
  "city: string",
  "clientId: string",
  "companyName: string",
  "dialect: string",
  "filterBenefits: string",
  "filterCity: string",
  "filterCompanyName: string",
  "filterJobTitle: string",
  "filterJobType: string",
  "filterOfficeAddress: string",
  "filterPostingDateStartDate: string",
  "filterQualification: string",
  "filterSalary: string",
  "filterWorkArrangement: string",
  "hiringManager: string",
  "jobDescription: string",
  "jobOverview: string",
  "jobPostStatus: string",
  "jobTitle: string",
  "jobType: string",
  "job_id: string",
  "maxSalary: number",
  "minSalary: number",
  "officeAddress: string",
  "officeHours: string",
  "postingDate: map",
  "postingDateEndDateISO: string",
  "postingDateStartDateISO: string",
  "qualification: string",
  "skillSet: list of strings",
  "workArrangement: string",
];

export const workHistory = [
  "id: string",
  "createdAt: string",
  "applicantId: string",
  "companyName: string",
  "employmentType: string",
  "experienceDate: map",
  "industry: string",
  "isDeleted: number",
  "isPresent: number",
  "jobLevel: string",
  "jobRole: string",
  "updatedAt: string",
  "workSetup: string",
  "yearsOfExperience: number",
];

export const applicants = [
  "jobId: string",
  "talentId: string",
  "apply: string",
  "birthdate: string",
  "clientId: string",
  "companyMunicipality: string",
  "companyName: string",
  "createdAt: string",
  "dateApplied: string",
  "jobTitle: string",
  "location: string",
  "resume: string",
  "status: string",
  "totalExperience: number",
];

export const certificates = [
  "id: string",
  "createdAt: string",
  "applicantId: string",
  "documentCategory: string",
  "documentName: string",
  "documentPath: string",
  "documentTitle: string",
  "isDeleted: number",
  "updatedAt: string",
  "validityDate: number",
];
