import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { resCode } from "../constants/resCode";

import axios from "axios";

export const useGenerate = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const { user } = useAuthContext();

  const generate = async (signupData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.data.token}`,
        },
        url: `${process.env.REACT_APP_SERVER_ENDPOINT}/hmac`,
        data: signupData,
      });

      const access = response.data.result;

      setAccessKeyId(access.accessKeyId);
      setSecretAccessKey(access.secretAccessKey);
    } catch (error) {
      setIsLoading(false);
      if (error.response) setError(resCode[error.response.data.resCode]);
      else setError(resCode[500]);
    }

    setIsLoading(false);
  };

  return { generate, accessKeyId, secretAccessKey, isLoading, error };
};
