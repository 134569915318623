import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

import Register from "./pages/Register";
import Generate from "./pages/Generate";
import Home from "./pages/Home";


function App() {
  const { user } = useAuthContext();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={!user ? <Home /> : <Navigate to="/generate" />}
          />
          <Route
            path="/generate"
            element={user ? <Generate /> : <Navigate to="/login" />}
          />
          <Route
            path="/signup"
            element={!user ? <Register /> : <Navigate to="/generate" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
